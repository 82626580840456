<template>
    <p>Copyright &copy; 2022 Justice Group</p>
</template>

<style scoped>
a {
  color: #333;
}

footer {
  clear: both;
  position: absolute;
  margin-top: -200px;
  text-align: center;
}
</style>
