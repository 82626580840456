<template>
  <div class="container">
    <Header
      title="AssaultCube: Tactics"
    />
    <div class = "links">
        <a href="https://github.com/normal420/ACPE">Github (download)</a> - 
        <a href="https://discord.gg/MNrtHG8v2p">Discord</a>
    </div>  
    <div class = "maintext"> 
      AssaultCube: Tactics is a fork of <a href="https://assault.cubers.net/">AssaultCube</a>, intended to provide a superior, community driven version of the game<br> 
      <br>
      It should be stressed that this project is currently in an early stage of development, and bugs/issues are to be expected. The project is open source.
      <br><br>

      <b>Features include:</b>
      <ul>
        <li>Superior weapon and gameplay balancing</li>
        <li>'Haxjump' physics</li>
        <li>No 'map restictions'; unlimited freedom for players to make map content </li>
        
        <li>GEMA (Parkour) mode</li>
        <li>New matchmode features: pause, 'sides'(team switch), halftime, server lock</li>
        <li>Modern UI</li>
        <li>New custom crosshair system. Classic crosshair system retained</li>
        <li>Dedicated masterserver</li>
      </ul>
    </div>
  </div>
  
    <Footer />
</template>
<script>

import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  created () {
            document.title = "AssaultCube: Tactics";
        },
  components: {
    Header,
    Footer,
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-image: url("assets/swamp.jpg");
  text-align: center;
  
  color:honeydew;
}

.container {
  max-width: 1000px;
  margin: 30px auto;
  overflow: auto;
  min-height: 750px;
  background-color: rgba(42, 42, 42, 0.9);
  border: 1px solid gray;
  padding: 30px;
  border-radius: 5px;
}

.maintext{
  text-align: left;
  margin: 30px auto;
}

.links{
  text-align: center;
  margin: 0;
  padding: 0;
}
</style>
